import { acceptHMRUpdate, defineStore } from 'pinia'


export const useTenantConfig = defineStore('tenantConfig', {

    state: () => ({
        tenantId: '',
        theme: {
            // Set some defaults
            brandName: 'Test',
            brandCompanyName: '', // Brands company name
            headerLogoImageSrc: '', // URL for logo to be used in header
            headerLogoImageAlt: '', // Alt text for logo to be used in header
            brandColorPrimary: '#0387EC', // Primary color for the brand
            brandColorSecondary: '#0387EC', // Secondary color for the brand
            showCartSteps: false, // Shows stepper on cart pages
        }
    }),

    getters: {
        currentTenantTheme: (state) => state.theme,
        pageTitlePrefix: (state) => `${state && state.theme && state.theme.brandName} - `,
    },

    actions: {
        // TODO: Fetch tenant theme from the server and update state.
        // (Can be found in firestore under tenant/${tenantId}/config/theme) - Document has "visibility: public" on it, so firestore rules should make it public data.
        fetchTenantTheme() {
            console.log(`fetchTenantTheme() not yet implemented`)
        },
        // Sets a fallback theme (default WeTix branding)
        setFallbackTheme() {
            this.theme = {
                // Set some defaults
                brandName: 'WeTix',
                brandCompanyName: 'WeTix', // Brands company name
                headerLogoImageSrc: 'https://assets-cdn.wetix.net/images/wetix-logo.svg', // URL for logo to be used in header
                headerLogoImageAlt: 'WeTix', // Alt text for logo to be used in header
                brandColorPrimary: '#0387EC', // Primary color for the brand
                brandColorSecondary: '#0387EC', // Secondary color for the brand
            }
        }
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useTenantConfig, import.meta.hot))
}
